<template>
  <Head>
    <title>{{ title }}</title>
    <meta property="description" name="description" :content="description" />
    <meta property="keywords" name="keywords" :content="keywords" />
  </Head>
  <div class="flex flex-column flex-grow-1">
    <AprBanner section="yieldPools" :docs-url="GITBOOK_LINKS.STAKING" />

    <StakingSteps
      :action="stakingSteps.action"
      :pool="stakingSteps.pool"
      class="mb-s3"
      v-if="isStakingStepsShown"
      @on-close="onHideSteps()"
    />

    <InfoMessage
      type="warning"
      :message="$t('wrappedContract.steps.infoMessage.someInProgress')"
      as-panel
      v-if="isSomeInProgress"
    />

    <template v-if="isAutoStakingStoreReady">
      <AutoBounty
        v-for="(autoStake, index) in getAutoStakingTableData"
        :auto-pool="autoStake"
        :key="`autobounty-${index}`"
        @send-transaction="onSendTransaction"
        class="mt-s3"
      />
    </template>

    <div class="panel-frame mt-s3">
      <div class="h3 text-bold">{{ t('yieldPool.yieldPools') }}</div>
      <template v-if="isStakingStoreReady">
        <AutoPool
          auto-staking
          v-for="(autoStake, index) in getAutoStakingTableData"
          :pool="autoStake"
          :key="`auto-${index}`"
          class="mt-s3"
          @send-transaction="onSendTransaction"
        />
        <Pool
          v-for="(stake, index) in getStakingTableData"
          :pool="stake"
          :key="index"
          class="mt-s3"
          @send-transaction="onSendTransaction"
        />
      </template>

      <PoolsLoadingPlaceholder v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { Head } from '@vueuse/head';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { GITBOOK_LINKS } from '@/constants/EXTERNAL_LINKS';
import { MODULE_NAMES } from '@/store';
import { STAKING_ACTION_TYPES } from '@/store/modules/staking/staking.module';
import { AUTO_STAKING_ACTION_TYPES } from '@/store/modules/staking/autoStaking.module';
import { FARMING_ACTION_TYPES } from '@/store/modules/farming/farming.module';
import { SEO_STAKING } from '@/constants/seo';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { asyncFnCancelable } from '@/utils/promise';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { useAllSteps } from '@/composables/useAllSteps';
import { useStakingSteps } from '@/store/modules/staking/useStakingSteps';
import AutoBounty from './autoBounty/AutoBounty.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import PoolsLoadingPlaceholder from '@/views/pages/investment/staking/PoolsLoadingPlaceholder.vue';
import AprBanner from '@/components/aprBanner/AprBanner';
import AutoPool from '@/views/pages/investment/staking/pool/autoPool/AutoPool';
import Pool from '@/views/pages/investment/staking/pool/Pool';
import StakingSteps from '@/views/pages/investment/staking/StakingSteps.vue';

export default {
  name: 'Staking',
  components: {
    InfoMessage,
    StakingSteps,
    PoolsLoadingPlaceholder,
    AutoPool,
    AprBanner,
    Pool,
    AutoBounty,
    Head,
  },
  setup() {
    const { walletState } = useEVMWallet();
    const allStepsState = useAllSteps();
    const { stakingSteps, setIsShown, prepareSteps, runSteps } = useStakingSteps();
    const isSomeInProgress = computed(
      () => !stakingSteps.inProgress && allStepsState.isInProgress.state,
    );
    const { t } = useI18n();

    return {
      walletState,
      // staking steps
      stakingSteps,
      setIsShown,
      prepareSteps,
      runSteps,
      // all steps
      allStepsState,
      isSomeInProgress,
      t,
    };
  },
  data() {
    return {
      isPools: true,
      updateController: new AbortController(),
      updateInterval: null,
    };
  },
  watch: {
    'walletState.isInjected': {
      handler: async function (val) {
        if (val) {
          await Promise.allSettled([this.initStaking(), this.initAutoStaking()]);
          if (!this.updateInterval) {
            this.updateInterval = setInterval(async () => {
              const staking = async () => {
                await this.getStatusStaking();
                await this.updateStakingPools();
              };
              const autoStaking = async () => {
                await this.getStatusAutoStaking();
                await this.updateAutoStaking();
              };

              await Promise.allSettled([staking(), autoStaking()]);
            }, UPDATE_INTERVAL);
          }
        } else {
          if (!this.updateInterval) {
            await Promise.allSettled([this.initStaking(), this.initAutoStaking()]);
            return;
          }

          this.updateInterval && clearInterval(this.updateInterval);
          // await this.getPairsWithBalances();
        }
      },
      immediate: true,
    },
  },
  async created() {
    try {
      await asyncFnCancelable(this.getTvlAmount, this.updateController.signal);
      this.updateInterval = setInterval(() => {
        this.getTvlAmount();
      }, UPDATE_INTERVAL);
    } catch (e) {
      console.debug(e);
    }
  },
  beforeUnmount() {
    this.updateController.abort();
    this.updateController = null;

    console.log('beforeUnmount', this.updateInterval);
    clearInterval(this.updateInterval);
    this.updateInterval = null;
  },
  methods: {
    ...mapActions(MODULE_NAMES.STAKING, {
      initStaking: STAKING_ACTION_TYPES.STAKING_INIT,
      getStatusStaking: STAKING_ACTION_TYPES.GET_STATUS_STAKING,
      updateStakingPools: STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS,
    }),
    ...mapActions(MODULE_NAMES.AUTO_STAKING, {
      initAutoStaking: AUTO_STAKING_ACTION_TYPES.AUTO_STAKING_INIT,
      getStatusAutoStaking: AUTO_STAKING_ACTION_TYPES.GET_STATUS_AUTO_STAKING,
      updateAutoStaking: AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING,
    }),
    ...mapActions(MODULE_NAMES.FARMING, {
      getTvlAmount: FARMING_ACTION_TYPES.GET_CURRENT_TOTAL_TVL,
    }),
    onShowSteps() {
      this.setIsShown(true);
    },
    onHideSteps() {
      this.setIsShown(false);
    },
    onSendTransaction() {
      if (ENABLE_FAKE_CARDANO_NETWORK) {
        this.prepareSteps();
        this.onShowSteps();
        this.runSteps();
      }
    },
  },
  computed: {
    GITBOOK_LINKS() {
      return GITBOOK_LINKS;
    },
    ...mapGetters(MODULE_NAMES.STAKING, ['getStakingTableData']),
    ...mapGetters(MODULE_NAMES.AUTO_STAKING, ['getAutoStakingTableData']),
    isStakingStoreReady() {
      return this.$store.state.staking.isStoreReady;
    },
    isAutoStakingStoreReady() {
      return this.$store.state.autoStaking.isStoreReady;
    },
    title() {
      return SEO_STAKING.TITLE;
    },
    description() {
      return SEO_STAKING.DESCRIPTION;
    },
    keywords() {
      return SEO_STAKING.KEYWORDS;
    },
    isStakingStepsShown() {
      return this.stakingSteps.isShown;
    },
  },
};
</script>

<style scoped></style>
